import React from 'react';
import { useWizard, Wizard } from 'react-use-wizard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
const answers = {}

const PreStep = () => {
    const { nextStep } = useWizard();

    return (
        <div className={"startScreen"}>
            <h3>Ist Ihre Organisation von NIS-2 betroffen?</h3>
            <p>Die Netz- und Informationssicherheit 2 (NIS-2) Richtlinie wird bis spätestens im Laufe des Oktober 2024 auf 18 Sektoren und auf zwei verschiedene Einstufungen ausgeweitet.</p>
            <p>Wir möchten Sie bei der Prüfung unterstützen, ob Ihre Organisation bzw. Ihr Unternehmen unter diese NIS-2 Richtlinie/Regelung fällt.</p>
            <p>Mithilfe weniger Fragen finden Sie heraus, ob Ihre Organisation bzw. Ihr Unternehmen von NIS-2 Richtlinie betroffen ist.</p>
            <p>Im Anschluss haben Sie Gelegenheit sich die Fragen und Ihre entsprechenden Antworten als PDF per Email zusenden zu lassen.</p>
            <button onClick={nextStep}>Start</button>
        </div>
    );
}

const Step1 = () => {
    function handleRadioChange(event) {
        answers[event.target.name] = event.target.value;
    }
    return (
        <>
            <h3>Wie viele Mitarbeiter beschäftigt Ihre Organisation bzw. Ihr Unternehmen?</h3>
            <p>Meine Organisation bzw. mein Unternehmen beschäftigt ...</p>
            <div className={"question"}>
                <input type={"radio"} name={"employees"} value={"u50"} id={"u50"} onChange={handleRadioChange}/> <label
                htmlFor={"u50"}>weniger als 50 Mitarbeiter.</label>
            </div>
            <div className={"question"}>
                <input type={"radio"} name={"employees"} value={"ue50"} id={"ue50"} onChange={handleRadioChange}/> <label
                htmlFor={"ue50"}>mehr als 50 Mitarbeiter.</label>
            </div>
            {/*            <div className={"question"}>
                <input type={"radio"} name={"employees"} value={"other"} id={"other"} onChange={handleRadioChange}/> <label
                htmlFor={"ue250"}>Vertrauensdienst</label>
            </div>*/}
        </>
    )
}

const Step2 = () => {
    function handleRadioChange(event) {
        answers[event.target.name] = event.target.value;
    }
    return (
        <>
            <h3>Wie hoch ist der Umsatz Ihrer Organisation bzw. Ihres Unternehmens?</h3>
            <p>Der Umsatz meiner Organisation bzw. meines Unternehmens beträgt ...</p>
            <div className={"question"}>
                <input type={"radio"} name={"revenue"} value={"u10u10B"} id={"u10u10B"} onChange={handleRadioChange} /> <label htmlFor={"u10u10B"}>weniger als 10 Millionen Euro und weniger als 10 Millionen Euro Bilanzsumme.</label>
            </div>
            <div className={"question"}>
                <input type={"radio"} name={"revenue"} value={"ue10oue10B"} id={"ue10oue10B"} onChange={handleRadioChange} /> <label htmlFor={"ue10oue10B"}>mehr als 10 Millionen Euro und mehr als 10 Millionen Euro Bilanzsumme.</label>
            </div>
            {/*            <div className={"question"}>
                <input type={"radio"} name={"revenue"} value={"other"} id={"other"} onChange={handleRadioChange} /> <label htmlFor={"other"}>qTSP, TLD, DNS, TK-Anbieter, kritische Anlagen</label>
            </div>*/}
        </>
    )

}

const Step3 = () => {
    function handleRadioChange(event) {
        answers[event.target.name] = event.target.value;
    }
    return (
        <>
            <h3>Ihre Organisation bzw. Ihr beschäftigt mehr als 50 Mitarbeiter. Um genauer herauszufinden, ob ihre Organisation unter NIS-2 fällt bitten wir Sie anzugeben ob ihre Organisation bzw. Ihr Unternehmen ...</h3>
            <div className={"question"}>
                <input type={"radio"} name={"employeesBig"} value={"u250"} id={"u250"} onChange={handleRadioChange} /> <label htmlFor={"u250"}>weniger als 250 Mitarbeiter beschäftigt.</label>
            </div>
            <div className={"question"}>
                <input type={"radio"} name={"employeesBig"} value={"ue250"} id={"ue250"} onChange={handleRadioChange} /> <label htmlFor={"ue250"}>mehr als 250 Mitarbeiter beschäftigt.</label>
            </div>
        </>
    )
}

const Step4 = () => {
    function handleRadioChange(event) {
        answers[event.target.name] = event.target.value;
    }
    return (
        <>
            <h3>Der Umsatz Ihrer Organisation bzw. Ihres Unternehmens beträgt mehr als 10 Millionen Euro und die Bilanzsumme beträgt mehr als 10 Millionen Euro. Bitte wählen Sie, ob Ihre Organisation bzw. Ihr Unternehmen:</h3>
            <div className={"question"}>
                <input type={"radio"} name={"revenueBig"} value={"u50u43"} id={"u50u43"} onChange={handleRadioChange} /> <label htmlFor={"u50u43"}>weniger als 50 Millionen Euro Umsatz und weniger als 43 Millionen Euro Bilanzsumme hat.</label>
            </div>
            <div className={"question"}>
                <input type={"radio"} name={"revenueBig"} value={"ue50oue43"} id={"ue50oue43"} onChange={handleRadioChange} /> <label htmlFor={"ue50oue43"}>mehr als 50 Millionen Euro Umsatz und über 43 Millionen Euro Bilanzsumme hat.</label>
            </div>
        </>
    )
}

const Step5 = () => {
    function handleRadioChange(event) {
        ////create array for all selected checkboxes

        if (!answers[event.target.name]) {
            answers[event.target.name] = [];
        }
        //if checkbox is checked add value to array
        if (event.target.checked) {
            answers[event.target.name].push(event.target.value);
        } else {
            //if checkbox is unchecked remove value from array
            answers[event.target.name] = answers[event.target.name].filter(e => e !== event.target.value);
        }
    }
    return (
        <>
            <h3>Ist Ihre Organisation bzw. Ihr Unternehmen in einem oder mehreren der unten aufgelisteten Sektoren tätig oder ein solcher Lieferant? (Mehrfachantworten möglich)</h3>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"energie"} id={"energie"} onChange={handleRadioChange}/>
                <label htmlFor={"energie"}>Energie</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"transport"} id={"transport"} onChange={handleRadioChange}/>
                <label htmlFor={"transport"}>Transport</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"bankwesen"} id={"bankwesen"} onChange={handleRadioChange}/>
                <label htmlFor={"energie"}>Bankwesen</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"gesundheitswesen"} id={"gesundheitswesen"} onChange={handleRadioChange}/>
                <label htmlFor={"gesundheitswesen"}>Gesundheitswesen</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"trinkabwasser"} id={"trinkabwasser"} onChange={handleRadioChange}/>
                <label htmlFor={"trinkabwasser"}>Trinkwasser / Abwasser</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"digitaleinfra"} id={"digitaleinfra"} onChange={handleRadioChange}/>
                <label htmlFor={"digitaleinfra"}>Digitale Infrastruktur</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"verwaltung"} id={"verwaltung"} onChange={handleRadioChange}/>
                <label htmlFor={"verwaltung"}>Verwaltung</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"raumfahrt"} id={"raumfahrt"} onChange={handleRadioChange}/>
                <label htmlFor={"raumfahrt"}>Raumfahrt</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"finanzmaerkte"} id={"finanzmaerkte"} onChange={handleRadioChange}/>
                <label htmlFor={"finanzmaerkte"}>Finanzmärkte</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor1"} value={"nein"} id={"nein"} onChange={handleRadioChange}/>
                <label htmlFor={"nein"}>Nein, meine Organisation bzw. mein Unternehmen ist in keinem NIS-2 relevanten Sektor tätig oder ein solcher Lieferant.</label>
            </div>
        </>
    )
}

const Step6 = () => {
    function handleRadioChange(event) {
        //answers[event.target.name] = event.target.value;
        //create array for all selected checkboxes
        if (!answers[event.target.name]) {
            answers[event.target.name] = [];
        }
        //if checkbox is checked add value to array
        if (event.target.checked) {
            answers[event.target.name].push(event.target.value);
        } else {
            //if checkbox is unchecked remove value from array
            answers[event.target.name] = answers[event.target.name].filter(e => e !== event.target.value);
        }
    }
    return (
        <>
            <h3>Ist Ihre Organisation bzw. Ihr Unternehmen ein Lieferant in einem oder mehreren der unten aufgelisteten Sektoren?</h3>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor2"} value={"post"} id={"post"} onChange={handleRadioChange}/>
                <label htmlFor={"energie"}>Post / Kurier</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor2"} value={"abfallwirtschaft"} id={"abfallwirtschaft"} onChange={handleRadioChange}/>
                <label htmlFor={"abfallwirtschaft"}>Abfallwirtschaft</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor2"} value={"industrie"} id={"industrie"} onChange={handleRadioChange}/>
                <label htmlFor={"industrie"}>Industrie</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor2"} value={"digitaledienste"} id={"digitaledienste"} onChange={handleRadioChange}/>
                <label htmlFor={"digitaledienste"}>Digitale Dienste</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor2"} value={"forschung"} id={"forschung"} onChange={handleRadioChange}/>
                <label htmlFor={"forschung"}>Forschung</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor2"} value={"chremischebetriebe"} id={"chremischebetriebe"} onChange={handleRadioChange}/>
                <label htmlFor={"chremischebetriebe"}>Chemische Betriebe</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor2"} value={"nahrungsmittelgewerbe"} id={"nahrungsmittelgewerbe"} onChange={handleRadioChange}/>
                <label htmlFor={"nahrungsmittelgewerbe"}>Nahrungsmittelgewerbe</label>
            </div>
            <div className={"question"}>
                <input type={"checkbox"} name={"sektor2"} value={"nein"} id={"nein"} onChange={handleRadioChange}/>
                <label htmlFor={"nein"}>Nein, meine Organisation bzw. mein Unternehmen ist kein Lieferant in einem NIS-2 relevanten Sektor.</label>
            </div>
        </>
    )
}

const Step7 = () => {
    function handleRadioChange(event) {
        answers[event.target.name] = event.target.value;
    }
    return (
        <>
            <h3>Ist Ihre Organisation bzw. Ihr Unternehmen ein qualifizierter Vertrauensdienst?</h3>
            <div className={"question"}>
                <input type={"radio"} name={"vertrauensdienst"} value={"vertrauensdienst"} id={"vertrauensdienst"} onChange={handleRadioChange}/>
                <label htmlFor={"vertrauensdienst"}>Ja, meine Organisation bzw. mein Unternehmen ist ein Vertrauensdienst.</label>
            </div>
            <div className={"question"}>
                <input type={"radio"} name={"vertrauensdienst"} value={"nein"} id={"nein"} onChange={handleRadioChange}/>
                <label htmlFor={"nein"}>Nein, meine Organisation bzw. mein Unternehmen ist kein Vertrauensdienst.</label>
            </div>
        </>
    )
}

const Step8 = () => {
    return (
        <>
            <SendMail/>
        </>
    )
}

const SendMail = () => {
    function handleCheckboxChange(event) {
        answers[event.target.name] = event.target.checked;
    }

    function handleTextChange(event) {
        answers[event.target.name] = event.target.value;
    }

    function sendMail() {
        //check if sendnis2 is checked must contact be checked
        if (answers.sendnis2 === true && answers.contact !== true) {
            toast.error('Um Ihre Orientierungshilfe zu erhalten, müssen Sie uns erlauben, Sie zu kontaktieren.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        //post answers to backend and send mail
        fetch('/sending', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(answers),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                toast.success('Ihre Anfrage wurde erfolgreich verarbeitet.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                document.getElementById("mailendform").style.display = "none";
                document.getElementById("mailendformsent").style.display = "block";
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            });
    }

    //function that check if contact is checked when not show warning
    function checkContact() {
        //show warnig when reportsend is checked and contact is not checked. Hide warning when contact is checked and reportsend is checked or not checked
        if (answers.reportsend === true && answers.contact !== true) {
            document.getElementById("warning").style.display = "block";
        } else {
            document.getElementById("warning").style.display = "none";
        }
    }

    function checkPrivacy() {
        checkContent();
        //show warnig when reportsend is checked and contact is not checked. Hide warning when contact is checked and reportsend is checked or not checked
        if (answers.dataprivacy !== true) {
            document.getElementById("privacywarning").style.display = "block";
        } else {
            document.getElementById("privacywarning").style.display = "none";
        }
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        //check if company, email and name is filled and if dataprivacy is checked. Also check if reportsend contact must be checked
        if (answers.email !== "" && answers.name !== "" && answers.company !== "" && answers.dataprivacy === true && answers.email && answers.name && answers.company && (answers.reportsend === true && answers.contact === true) || (answers.reportsend === false && answers.contact === false || answers.reportsend === false && answers.contact === true)) {
            if(!emailRegex.test(answers.email)) {
                document.getElementById("email").style.border = "1px solid red";
                document.getElementById("emailwarning").style.display = "block";
                return;
            }
            sendMail();
        } else {
            toast.error("Bitte füllen Sie alle Felder aus und stimmen Sie unseren Datenschutzbestimmungen zu.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }
    }

    function checkContent() {
        //show warnig when email, name or company is empty or does not exist in the answers object
        //check if email is an valid email format
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if(!emailRegex.test(answers.email)) {
            document.getElementById("email").style.border = "1px solid red";
            document.getElementById("emailwarning").style.display = "block";
        } else {
            document.getElementById("email").style.border = "1px solid black";
            document.getElementById("emailwarning").style.display = "none";
        }
        if (answers.email === "" || answers.name === "" || answers.company === "" || !answers.email || !answers.name || !answers.company) {
            //make every input red when empty
            if (answers.email === "" || !answers.email) {
                document.getElementById("email").style.border = "1px solid red";
            } else {
                document.getElementById("email").style.border = "1px solid black";
            }
            if (answers.name === "" || !answers.name) {
                document.getElementById("name").style.border = "1px solid red";
            } else {
                document.getElementById("name").style.border = "1px solid black";
            }
            if (answers.company === "" || !answers.company) {
                document.getElementById("company").style.border = "1px solid red";
            } else {
                document.getElementById("company").style.border = "1px solid black";
            }
            document.getElementById("contentwarning").style.display = "block";
        } else {
            document.getElementById("contentwarning").style.display = "none";
            if (answers.email === "" || !answers.email) {
                document.getElementById("email").style.border = "1px solid red";
            } else {
                document.getElementById("email").style.border = "1px solid black";
            }
            if (answers.name === "" || !answers.name) {
                document.getElementById("name").style.border = "1px solid red";
            } else {
                document.getElementById("name").style.border = "1px solid black";
            }
            if (answers.company === "" || !answers.company) {
                document.getElementById("company").style.border = "1px solid red";
            } else {
                document.getElementById("company").style.border = "1px solid black";
            }
        }
    }


    return (
        <>
            <div className={"EndForm"} id={"mailendform"}>
                <h3>Bitte tragen Sie ihre Kontaktdaten ein, um ihre Orientierungshilfe per Email zu erhalten.</h3>
                <div className={"contact"}>
                    <label htmlFor={"company"}>Name der Organisation: <span className={"required"}>*</span></label>
                    <input type={"text"} name={"company"} id={"company"} onChange={handleTextChange} required={true}/>
                    <label htmlFor={"email"}>Ihre E-Mail-Adresse: <span className={"required"}>*</span></label>
                    <input type={"email"} name={"email"} id={"email"} onChange={handleTextChange} required={true}
                           pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
                    <label htmlFor={"name"}>Ihr Name: <span className={"required"}>*</span></label>
                    <input type={"text"} name={"name"} id={"name"} onChange={handleTextChange} required={true}/>
                    <label htmlFor={"phone"}>Ihre Telefonnummer:</label>
                    <input type={"text"} name={"phone"} id={"phone"} onChange={handleTextChange}/>
                </div>
                <div className={"checkbox"}>
                    <label htmlFor={"dataprivacy"}>Ich stimme den <a href={"https://rr-engineering.de/datenschutz"}>RR Engineering Datenschutzbestimmungen</a> zu. Sowie der Verarbeitung der angegebenen Daten für die Auswertung und das Versenden der E-Mail zu. <span className={"required"}>*</span></label>
                    <input type={"checkbox"} name={"dataprivacy"} id={"dataprivacy"} onChange={handleCheckboxChange}/>
                </div>
                <div className={"checkbox"}>
                    <label htmlFor={"reportsend"}>Ich stimme zu, dass RR Engineering der oben genannten E-Mail-Adresse eine Nachricht zusenden darf. <span className={"required_two"}>**</span></label>
                    <input type={"checkbox"} name={"reportsend"} id={"reportsend"} onChange={(event) => {
                        handleCheckboxChange(event);
                        checkContact();
                    }}/>
                </div>
                <div className={"checkbox"}>
                    <label htmlFor={"contact"}>Ich stimme zu, dass RR Engineering mich mit den oben genannten Kontaktdaten kontaktieren darf. <span className={"required_two"}>**</span></label>
                    <input type={"checkbox"} name={"contact"} id={"contact"} onChange={(event) => {
                        handleCheckboxChange(event);
                        checkContact();
                    }}/>
                </div>
                <br/>
                <div id={"warning"} className={"error"} style={{display: 'none'}}>Um ihren Bericht zu erhalten, müssen Sie unseren Datenschutzbestimmungen zustimmen.
                </div>
                <div id={"privacywarning"} className={"error"} style={{display: 'none'}}>Um ihren Report zu erhalten, müssen
                    Sie unseren Datenschutzbestimmungen zustimmen.
                </div>
                <div id={"contentwarning"} className={"error"} style={{display: 'none'}}>Bitte füllen Sie alle Felder aus.
                </div>
                <div id={"emailwarning"} className={"error"} style={{display: 'none'}}>Bitte geben Sie eine gültige E-Mail-Adresse ein.
                </div>
                <br/>
                <p className={"required_bottom_text"}>Die mit <span className={"required"}>*</span> gekennzeichneten Felder
                    sind Pflichtfelder.</p>
                <p className={"required_bottom_text"}>Die mit <span className={"required_two"}>**</span> gekennzeichneten
                    Felder werden benötigt, um Ihnen die Orientierungshilfe zuzusenden.</p>
                <button onClick={checkPrivacy}>Senden</button>
            </div>
            <div className={"EndForm"} id={"mailendformsent"} style={{display: 'none'}}>
                <h1 style={{color: '#003E45'}}>Vielen Dank für Ihre Anfrage.</h1>
                <p>Ihre Anfrage wurde erfolgreich verarbeitet. Sie erhalten in Kürze eine E-Mail mit Ihrer Orientierungshilfe.</p>
                <h5 style={{color: '#003E45', marginTop: '50px'}}>Sie können dieses Fenster jetzt schließen.</h5>
            </div>
        </>
    )


}

const Footer = () => {
    const {
        nextStep,
        previousStep,
        isLoading,
        activeStep,
        stepCount,
        isLastStep,
        isFirstStep,
        goToStep,
    } = useWizard();

    //if first step check first answer and go to step 2
    function nextStepCheck() {
        if (activeStep === 1) {
            if (answers.employees === "u50") {
                goToStep(2)
            } else if (answers.employees === "ue50") {
                goToStep(3)
            } else {
                toast.error('Bitte wählen Sie eine Option aus.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } else if (activeStep === 2) {
            if (answers.revenue === "u10u10B") {
                goToStep(5)
            } else if (answers.revenue === "ue10oue10B") {
                goToStep(4)
            } else {
                toast.error('Bitte wählen Sie eine Option aus.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } else if (activeStep === 3) {
            if (answers.employeesBig === "u250") {
                goToStep(2)
            } else if (answers.employeesBig === "ue250") {
                goToStep(2)
            } else {
                toast.error('Bitte wählen Sie eine Option aus.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } else if (activeStep === 4) {
            if (answers.revenueBig === "u50u43") {
                goToStep(5)
            } else if (answers.revenueBig === "ue50oue43") {
                goToStep(5)
            } else {
                toast.error('Bitte wählen Sie eine Option aus.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } else if (activeStep === 5) {
            if (answers.sektor1 !== "" && answers.sektor1) {
                //if sektor1 has nein and any other value in the array show error
                if (answers.sektor1.includes("nein") && answers.sektor1.length > 1) {
                    toast.error('Sie haben "Nein" ausgewählt und gleichzeitig einen oder mehrere Sektoren ausgewählt. Bitte wählen Sie entweder "Nein" oder einen oder mehrere Sektoren aus.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    return;
                }
                goToStep(6)
            } else {
                toast.error('Bitte wählen Sie eine Option aus.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } else if (activeStep === 6) {
            if (answers.sektor2 !== "" && answers.sektor2) {
                //if sektor2 has nein and any other value in the array show error
                if (answers.sektor2.includes("nein") && answers.sektor2.length > 1) {
                    toast.error('Sie haben "Nein" ausgewählt und gleichzeitig einen oder mehrere Sektoren ausgewählt. Bitte wählen Sie entweder "Nein" oder einen oder mehrere Sektoren aus.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    return;
                }
                goToStep(7)
            } else {
                toast.error('Bitte wählen Sie eine Option aus.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } else if (activeStep === 7) {
            if (answers.vertrauensdienst !== "" && answers.vertrauensdienst) {
                goToStep(8)
            } else {
                toast.error('Bitte wählen Sie eine Option aus.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } else {
            toast.error('Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    return (
        <div className={"Footer"}>
            {/*            <p>
                Debugging info: <br/>
            </p>
            <div style={{display: 'flex', gap: '1rem'}}>
                <p>Has previous step: {!isFirstStep ? '✅' : '⛔'}</p>
                <br/>
                <p>Has next step: {!isLastStep ? '✅' : '⛔'} </p>
                <br/>
                <p>
                    Active step: {activeStep + 1} <br/>
                </p>
                <br/>
                <p>
                    Total steps: {stepCount} <br/>
                </p>
            </div>*/}
            <div>
                {/*                <button
                    onClick={() => previousStep()}
                    disabled={isLoading || isFirstStep}
                >
                    Previous
                </button>*/}
                {(isFirstStep || isLastStep) ? null : (
                    <button onClick={() => nextStepCheck()} disabled={isLoading || isLastStep}>
                        Weiter
                    </button>
                )}
            </div>
        </div>
    );
};

const App = () => {
    return (
        <div className={"App"}>
            <div className={"App-logo"}>
                NIS2
                <div className={"App-logo-subtitle"}>
                    Orientierungshilfe
                </div>
            </div>
            <ToastContainer />
            <div className={"Form"}>
                <Wizard footer={<Footer />}>
                    <PreStep />
                    <Step1 />
                    <Step2 />
                    <Step3 />
                    <Step4 />
                    <Step5 />
                    <Step6 />
                    <Step7 />
                    <Step8 />
                </Wizard>
            </div>
            <div className={"rechtliches"}>
                <a href={"https://rr-engineering.de/datenschutz"}>Datenschutz</a> | <a href={"https://rr-engineering.de/impressum"}>Impressum</a>
            </div>
        </div>
    );
};

export default App;